<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >
          <!-- Resource Group tab -->
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                class="mb-2"
                cols="12"
              />
              <b-col md="12">
                <b-row
                  v-for="(trench, index) in trenches"
                  :key="index"
                  class="mb-2"
                >
                  <b-col>
                    <b-form-group
                      label="Trench Title"
                      label-for="Trench Title"
                    >
                      <b-form-input
                        id="trenchTitle"
                        v-model="trench.title"
                        placeholder="Trench Title"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Trench Amount"
                      label-for="trenchAmount"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Trench Amount"
                        rules=""
                      >
                        <b-form-input
                          id="trenchAmount"
                          v-model="trench.trenchAmount"
                          type="number"
                          placeholder="Trench Amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-row class="ml-25 border-left">
                      <b-col
                        class="my-1"
                        cols="12"
                      >
                        <b-row>
                          <b-col
                            cols="auto"
                          ><h6 class="mt-25">
                            Startup Input Form
                          </h6></b-col>
                          <b-col>
                            <hr>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="12">
                        <transition-group name="list">
                          <b-row
                            v-for="(criteria, index) in criterias"
                            :key="criteria.id"
                          >
                            <b-col>
                              <b-form-group
                                :label="`Criteria ${index+1}`"
                                :label-for="`criteria-${index+1}`"
                              >
                                <b-form-input
                                  :id="`criteria-${index+1}`"
                                  v-model="criteria.title"
                                  type="text"
                                  placeholder="Criteria title"
                                  required
                                />
                              </b-form-group>
                            </b-col>
                            <b-col cols="auto">
                              <b-button
                                v-if="index === criterias.length - 1"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2 mt-2"
                                @click="criterias.push({
                                  id:Math.random(),
                                  title: '',
                                  items: [{
                                    id: 1,
                                    question: '',
                                    dataType: '',
                                    prevHeight: 0,
                                  }],
                                })"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  class="mr-25"
                                />
                                <span>Add Criteria</span>
                              </b-button>
                              <b-button
                                v-if="criterias.length > 1"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="danger"
                                class="mt-2"
                                @click="criterias.splice(index, 1)"
                              >
                                <feather-icon
                                  class="mr-25"
                                  icon="XIcon"
                                />
                                <span>Remove</span>
                              </b-button>
                            </b-col>
                          </b-row></transition-group></b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },

  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      criterias: [
        {
          id: 1,
          title: '',
        },
      ],
      startup: '',
      valuation: null,
      fundsSanctioned: null,
      partners: null,
      trenches: [
        {
          title: null,
          trenchAmount: null,
        },
      ],
      required,
      email,
    }
  },
  // props: ['inheritedSections'],
  methods: {
    notifyParent() {
      this.$emit('input', this.sections)
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },

}
</script>

<style lang="scss">
.vs__dropdown-menu{
  z-index: 1051 !important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
