var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"subtitle":null,"title":null,"back-button-text":"Previous","color":"#7367F0","finish-button-text":"Submit","shape":"square"},on:{"on-complete":_vm.formSubmitted}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}}),_c('b-col',{attrs:{"md":"12"}},_vm._l((_vm.trenches),function(trench,index){return _c('b-row',{key:index,staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Trench Title","label-for":"Trench Title"}},[_c('b-form-input',{attrs:{"id":"trenchTitle","placeholder":"Trench Title","type":"text"},model:{value:(trench.title),callback:function ($$v) {_vm.$set(trench, "title", $$v)},expression:"trench.title"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Trench Amount","label-for":"trenchAmount"}},[_c('validation-provider',{attrs:{"name":"Trench Amount","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"trenchAmount","type":"number","placeholder":"Trench Amount"},model:{value:(trench.trenchAmount),callback:function ($$v) {_vm.$set(trench, "trenchAmount", $$v)},expression:"trench.trenchAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"ml-25 border-left"},[_c('b-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"auto"}},[_c('h6',{staticClass:"mt-25"},[_vm._v(" Startup Input Form ")])]),_c('b-col',[_c('hr')])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('transition-group',{attrs:{"name":"list"}},_vm._l((_vm.criterias),function(criteria,index){return _c('b-row',{key:criteria.id},[_c('b-col',[_c('b-form-group',{attrs:{"label":("Criteria " + (index+1)),"label-for":("criteria-" + (index+1))}},[_c('b-form-input',{attrs:{"id":("criteria-" + (index+1)),"type":"text","placeholder":"Criteria title","required":""},model:{value:(criteria.title),callback:function ($$v) {_vm.$set(criteria, "title", $$v)},expression:"criteria.title"}})],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[(index === _vm.criterias.length - 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2 mt-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.criterias.push({
                                id:Math.random(),
                                title: '',
                                items: [{
                                  id: 1,
                                  question: '',
                                  dataType: '',
                                  prevHeight: 0,
                                }],
                              })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Criteria")])],1):_vm._e(),(_vm.criterias.length > 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.criterias.splice(index, 1)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Remove")])],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }